import image1 from '../../images/image1.jpg'
import image2 from '../../images/image2.jpg'
import image3 from '../../images/image3.jpg'
import image4 from '../../images/image4.jpg'
import image5 from '../../images/image5.jpg'
import image6 from '../../images/image6.jpg'
import image7 from '../../images/image7.jpg'
import image8 from '../../images/image8.jpg'
import image9 from '../../images/image9.jpg'
import image10 from '../../images/image10.jpg'
import image11 from '../../images/image11.jpg'
import image12 from '../../images/image12.jpg'

import '../../style/images.css'

export function Images() {
    return(
        <div className='image_banner_wrapper'>
            <img src={image1} alt='image1' />
            <img src={image2} alt='image2' />
            <img src={image3} alt='image3' />
            <img src={image4} alt='image4' />
            <img src={image5} alt='image5' />
            <img src={image6} alt='image6' />
            <img src={image7} alt='image7' />
            <img src={image8} alt='image8' />
            <img src={image9} alt='image9' />
            <img src={image10} alt='image10' />
            <img src={image11} alt='image11' />
            <img src={image12} alt='image12' />
        </div>
    )
}