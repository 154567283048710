export const WebsiteName = 'Complete Coverage Life Insurance'
export const WebsiteNameShort = 'Complete Coverage'


export const CardOneHeader  = 'MORTGAGE PROTECTION'
export const CardOneParagraph = 'Do you have a plan to pay your mortgage in the event of your death? Don’t lose your family’s most valuable asset due to poor planning.'

export const CardTwoHeader = 'LIFE INSURANCE'
export const CardTwoParagraph = 'How much coverage is enough? Make sure your family is protected with enough coverage to provide for them when you are gone.'

export const CardThreeHeader = 'FUTURE INCOME PLANNING'
export const CardThreeParagraph = 'Are you concerned with market volatility? There’s a little known secret to keep you money safe during market corrections.'