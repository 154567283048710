import { useState } from 'react'
import { Cards } from './cards'
import { Images } from './images'
import { WebsiteName } from '../../config'
import { SForm } from '../index.js'
import complete from '../../images/complete.png'
import '../../style/home.css'

export function Home() {
    const [ show, setShow ] = useState(false)
    function changeShow() {
        setShow(!show)
    }
    return(
        <div className='home_wrapper'>
            <section className='top_wrapper'>
                <div className='top_banner'>
                    <h3>
                        { WebsiteName }
                    </h3>
                    <img src={ complete } alt='complete' />
                    <button onClick={() => {
                        changeShow()
                    }}>
                        Get Your Free Quote
                    </button>
                </div>
            </section>
            {show?
                <section className='form_wrapper'>
                    <SForm />
                </section>
            :
                <section  className='form_wrapper'>
                    <Cards />
                </section>
            }
            <section>
                <Images />
            </section>
        </div>
    )
}