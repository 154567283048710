import { CardOneHeader, CardOneParagraph, CardTwoHeader, CardTwoParagraph, CardThreeHeader, CardThreeParagraph } from '../../config'
import home from '../../images/home.png'
import heart from '../../images/heart.png'
import money from '../../images/money.png'

import '../../style/cards.css'

export function Cards() {
    return(
        <div className='cards_wrapper'>
            <div className='cards'>
                <img src={ home } alt='home' />
                <h5>
                    { CardOneHeader }
                </h5>

                <p>
                    { CardOneParagraph }
                </p>
            </div>
            <div className='cards'>
                <img src={ heart } alt='heart' />
                <h5>
                    { CardTwoHeader }
                </h5>

                <p>
                    { CardTwoParagraph }
                </p>
            </div>
            <div className='cards'>
                <img src={ money } alt='money' />
                <h5>
                    { CardThreeHeader }
                </h5>

                <p>
                    { CardThreeParagraph }
                </p>
            </div>
        </div>
    )
}