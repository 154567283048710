import { Form, Card } from 'react-bootstrap'
import Api from '../../axios'
import '../../style/form.css'

export function Step1({nextStep, handleInput, values}) {
    function stepOne(e) {
        e.preventDefault()
        const newLead = {
            client_name: values.firstName + ' ' + values.lastName,
            mobile: values.mobile,
            address: values.address,
            country: values.country,
            state: values.state,
            city: values.city,
            postal_code: values.postal_code,
        }
        Api.post(`lead/new`, newLead)
        .then(res => {
            console.log(res.data)
            nextStep()
        })
        .catch(err => {
            console.error(err)
        })
    }

    return(
        <Card className='step_one_form'>
            <Card.Body>
                <Form onSubmit={stepOne}>
                    <div className='form_row_1'>
                        <Form.Group className='form_item'>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                                name='firstName'
                                defaultValue={values.firstName}
                                type='text'
                                placeholder='First Name...'
                                onChange={handleInput('firstName')}
                            />
                        </Form.Group>
                        <Form.Group className='form_item'>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                                name='lastName'
                                defaultValue={values.lastName}
                                type='text'
                                placeholder='Last Name...'
                                onChange={handleInput('lastName')}
                            />
                        </Form.Group>
                        <Form.Group className='form_item'>
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                name='mobile'
                                defaultValue={values.mobile}
                                type='tel'
                                placeholder='(000)-000-0000'
                                onChange={handleInput('mobile')}
                            />
                        </Form.Group>
                    </div>
                    <div className='form_row_2'>
                        <Form.Group className='form_item_wide'>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                name='address'
                                defaultValue={values.address}
                                type='text'
                                placeholder='123 Address St'
                                onChange={handleInput('address')}
                            />
                        </Form.Group>
                        <Form.Group className='form_item'>
                            <Form.Label>Postal Code</Form.Label>
                            <Form.Control
                                name='postal_code'
                                defaultValue={values.postal_code}
                                type='text'
                                placeholder='00000'
                                onChange={handleInput('postal_code')}
                            />
                        </Form.Group>
                    </div>
                    <div className='form_row_3'>
                        <Form.Group className='form_item_small'>
                            <Form.Label>State</Form.Label>
                            <Form.Control
                                name='state'
                                defaultValue={values.state}
                                type='text'
                                placeholder='NY'
                                onChange={handleInput('state')}
                            />
                        </Form.Group>
                        <Form.Group className='form_item_small_others'>
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                name='city'
                                defaultValue={values.city}
                                type='text'
                                placeholder='Utica'
                                onChange={handleInput('city')}
                            />
                        </Form.Group>
                        <Form.Group className='form_item_small_others'>
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                                name='country'
                                defaultValue={values.country}
                                type='text'
                                placeholder='United States'
                                onChange={handleInput('country')}
                            />
                        </Form.Group>
                    </div>
                    <div className='form_button_group'>
                        <button type='submit'>
                            Next Step →
                        </button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    )
}

export function Step2({prevStep, handleInput, values}) {
    function onSubmit(e) {
        e.preventDefault()
        const update = {
            age: values.age,
            client_email: values.email,
            employmentStatus: values.employmentStatus,
            beneficiary: values.beneficiary,
            currentLifeInsurance: values.currentLifeInsurance
        }
        Api.post(`lead/person/${values.mobile}`, update)
        .then(res => {
            console.log(res.data)
            setTimeout(() => {
                window.location.reload()
            }, 5000);
        })
        .catch(err => {
            console.error(err)
        })
    }


    return(
        <Card className='step_two_form'>
            <Card.Body>
                <Form onSubmit={onSubmit}>
                    <div className='form_row_1'>
                        <Form.Group className='form_item_small'>
                            <Form.Label>Age</Form.Label>
                            <Form.Control
                                name='age'
                                defaultValue={values.age}
                                type='number'
                                onChange={handleInput('age')}
                                placeholder='40'
                            />
                        </Form.Group>
                        <Form.Group className='form_item_small_others'>
                            <Form.Label>Do you currently have life insurance?</Form.Label>
                            <Form.Control
                                name='currentLifeInsurance'
                                defaultValue={values.currentLifeInsurance}
                                placeholder='Yes or No'
                                type='text'
                                onChange={handleInput('currentLifeInsurance')}
                            />
                        </Form.Group>
                        <Form.Group className='form_item_small_others'>
                            <Form.Label>Employment Status</Form.Label>
                            <Form.Control
                                name='employmentStatus'
                                defaultValue={values.employmentStatus}
                                type='text'
                                placeholder='Retired or Employed?'
                                onChange={handleInput('employmentStatus')}
                            />
                        </Form.Group>
                    </div>
                    <div className='form_row_2'>
                        <Form.Group className='form_item_wide_2'>
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                name='phoneNumber'
                                defaultValue={values.email}
                                type='email'
                                placeholder='email@gmail.com'
                                onChange={handleInput('email')}
                            />
                        </Form.Group>
                        <Form.Group className='form_item_wide_2'>
                            <Form.Label>Who will be your beneficiary?</Form.Label>
                            <Form.Control
                                name='beneficiary'
                                defaultValue={values.beneficiary}
                                type='text'
                                placeholder="Enter beneficiary's Name"
                                onChange={handleInput('beneficiary')}
                            />
                        </Form.Group>
                    </div>
                    <div className='form_button_group'>
                        <button onClick={prevStep}>
                            ← Previous
                        </button>
                        <button type='submit'>
                            Submit {' '}
                        </button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    )
}