import { useState } from 'react'
import { Step1, Step2 } from './steps'

const initialValues = {
    firstName: '',
    lastName: '',
    client_name: '',
    mobile: '',
    country: '',
    state: '',
    city: '',
    address: '',
    postal_code: '',
    age: '',
    client_email: '',
    employmentStatus: '',
    beneficiary: '',
    currentLifeInsurance: ''
}

const initialErrors = {
    firstName: '',
    lastName: '',
    client_name: '',
    mobile: '',
    country: '',
    state: '',
    city: '',
    address: '',
    postal_code: '',
    age: '',
    client_email: '',
    employmentStatus: '',
    beneficiary: '',
    currentLifeInsurance: ''
}

export function SForm() {
    const [ step, setStep ] = useState(1)

    const [ formData, SetFormData ] = useState( initialValues )
    const [ formErrors, setFormErrors ] = useState( initialErrors )

    function nextStep() {
        setStep(step + 1)
    }

    function prevStep() {
        setStep(step - 1)
    }

    const handleInput = input => e => {
        const { value } = e.target

        SetFormData(prevState => ({
            ...prevState, [input]: value
        }))
    }

    switch(step) {
        case 1:
            return(
                <>
                    <Step1 nextStep={nextStep} handleInput={handleInput} values={formData} errors={formErrors} />
                </>
            )
        case 2:
            return(
                <>
                    <Step2 prevStep={prevStep} handleInput={handleInput} values={formData} errors={formErrors} />
                </>
            )
        default:
            return (
                <>
                </>
            )
    }
}