import { TosWords } from './words'
import '../../style/tos.css'

export function Tos() {
    return(
        <div className='tos_wrapper'>
            <div className='tos_item'>
                <TosWords />
            </div>
        </div>
    )
}