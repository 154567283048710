import { Container, Navbar } from 'react-bootstrap'
import complete from '../../images/complete_logo.png'
import '../../style/navigation.css'

export function MainNav () {
    return(
        <Navbar bg='dark' variant='dark' className='main_nav'>
            <Container>
                <Navbar.Brand href='/'>
                    <img src={complete} alt='complete'/>
                    {' '}
                    Complete Coverage Life
                </Navbar.Brand>
            </Container>
        </Navbar>
    )
}

export function MainFooter() {
    return(
        <div className='footer'>
            <div className='footer_inner'>
                <h5>
                    Copyright © 2021-2022 Complete Coverage Life
                </h5>
                <p>
                    All rights reserved. | <a href='/tos'>Terms of Service</a>
                </p>
            </div>
        </div>
    )
}