import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { MainNav, MainFooter } from './components/navigation'
import { Home, Tos } from './components'

export function App() {
  return (
    <>
      <MainNav />
      <Router>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/tos' element={<Tos />} />
        </Routes>
      </Router>
      <MainFooter />
    </>
  )
}

